import { getConfirmation } from '~/components/form-push/adapter';
import {
  formStepsSerializer,
  getContent,
} from '~/components/form-steps-simple/adapter';
export default async ({ primary }, _data, context) => {
  const formStepsId = primary?.form?.id;
  const { $enhancedLinkSerializer, $prismic } = context;

  if (!formStepsId) {
    return null;
  }

  const request = await $prismic.client.getByID(formStepsId);

  if (!request?.id) {
    return null;
  }

  const { data } = request;

  const content = await getContent(primary, _data, context);

  const confirmation = await getConfirmation(
    data,
    data?.cross_sell_group,
    formStepsId,
    $enhancedLinkSerializer,
    $prismic
  );

  const form = await formStepsSerializer(formStepsId, data, context);

  return {
    form,
    content,
    confirmation,
  };
};
